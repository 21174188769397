<template>
  <v-layout wrap justify-start pa-2 pa-sm-4 pa-md-8>
    <v-flex xs12>
      <v-layout wrap justify-start pb-4>
        <v-spacer />
        <v-flex xs12 sm12 md12 lg8 xl6>
          <v-layout wrap justify-end>
            <v-flex xs6 sm6 md3 align-self-center pa-2 px-2>
              <AppDatePicker
                v-model="filter.date"
                :min="minDate"
                :max="maxDate"
                range
                :placeholder="$t('pages.schedules.select_month')"
                @submit="prepareFetchData(false)"
                hideDetails
              />
            </v-flex>
            <v-flex xs6 sm6 md3 text-right align-self-center pa-2 px-2>
              <v-select
                :key="filter.type"
                :placeholder="$t('filters.type')"
                :items="SHIFT_STATUS"
                outlined
                dense
                hide-details
                v-model="filter.type"
                item-text="label"
                item-value="key"
                clearable
                @change="prepareFetchData(false)"
              ></v-select>
            </v-flex>
            <v-flex xs6 sm6 md3 align-self-center text-right pa-2>
              <AppNavButton
                :route="{ name: 'services.add' }"
                custom-class="pa-5"
                block
              >
                <template #content>
                  <v-icon size="14px">mdi-plus</v-icon>
                  {{ $t("pages.services.add_service") }}
                </template>
              </AppNavButton>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start>
        <v-flex xs12>
          <AppSimpleTable
            :headings="headings"
            :items="list"
            :pagination="pagination"
            empty-title="pages.services.no_service_to_show"
            @paginate="
              (val) => ((pagination.page = val), this.prepareFetchData(true))
            "
            @delete="toggleDelete"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <DeleteConfirmation
      v-model="showDelete"
      :item="selectedItem"
      @cancel="toggleDelete"
      @submit="deleteService"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import Helpers from "@/libs/helper/components/helper-functions";
import AppDatePicker from "@/components/AppDatePicker.vue";
import { SHIFT_STATUS } from "@/constants/shifts";

export default {
  name: "Services",
  components: {
    AppDatePicker,
  },
  data: () => ({
    selectedItem: {},
    showDelete: false,
    filter: {
      date: null,
      type: null,
      search: null,
      sort_by: ["start_date"],
      sort_by_dir: ["desc"],
    },
    headings: [
      { name: "", value: "index", style: "width:80px" },
      { name: "form.ward", value: "ward" },
      { name: "form.department", value: "department" },
      { name: "form.start_date", value: "date" },
      { name: "table.total_shifts", value: "shifts" },
      { name: "table.unassigned_shifts", value: "unassigned_shifts" },
      {
        name: "table.actions",
        value: "action",
        style: "max-width:200px",
        options: ["view"],
        view: "service",
      },
    ],
    pagination: {
      per_page: 25,
      page: 1,
      last_page: 1,
      total: 0,
      from: 0,
      to: 0,
    },
    SHIFT_STATUS,
  }),
  computed: {
    ...mapGetters({
      services: "hospital/getServices",
    }),
    minDate() {
      return "2024-01";
    },
    maxDate() {
      return this.$moment().add(18, "months").format("YYYY-MM");
    },
    list() {
      return this.services.map((itm, index) => ({
        index: this.pagination.from + index,
        id: itm.id,
        case_no: itm.case_no,
        name: itm.name,
        department: itm?.departments[0]?.department?.name ?? "-",
        date: this.$moment(itm.start_date).format("DD MMM YYYY"),
        unassigned_shifts: itm.unassigned_shifts,
        ward: itm.ward?.name ?? "-",
        shifts: itm.shift_time.length,
        rowStyle: {
          backgroundColor: this.compareDates(itm.end_date)
            ? ""
            : itm.unassigned_shifts
            ? "#ff520045"
            : "",
        },
      }));
    },
  },
  created() {
    // Initialize the filter values from the query parameters
    // Set the date filter, defaulting to the start and end of the current month if newDate is empty or not provided
    this.filter.date = Array.isArray(this.$route.query.date)
      ? this.$route.query.date
      : this.$route.query.date
      ? [this.$route.query.date]
      : [
          this.$moment().startOf("month").format("YYYY-MM-DD"),
          this.$moment().endOf("month").format("YYYY-MM-DD"),
        ];
    this.filter.type = parseInt(this.$route.query.type) || null;
    this.pagination.page = parseInt(this.$route.query.page) || 1;
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    compareDates(inputDate) {
      const currentDate = this.$moment();
      const input = this.$moment(inputDate, "YYYY-MM-DD");
      return input.isBefore(currentDate);
    },

    updateQueryParams() {
      // Update the query parameters based on filter and pagination values
      this.$router.replace({
        query: {
          date: this.filter.date,
          type: this.filter.type,
          page: this.pagination.page,
        },
      });
    },
    toggleDelete(val) {
      if (val) this.selectedItem = val;
      else this.selectedItem = {};
      this.showDelete = !this.showDelete;
    },
    prepareFetchData(pagination = false) {
      if (!pagination) this.pagination.page = 1;
      this.updateQueryParams();
      this.getData();
    },
    getData() {
      const payload = {
        ...this.pagination,
        ...this.filter,
        from: this.filter.date[0],
        to: this.filter.date[1] ?? this.filter.date[0],
      };
      this.$store.dispatch("hospital/getServiceList", payload).then((res) => {
        this.pagination = Helpers.setPagination(res);
      });
    },
    deleteService() {
      this.$store
        .dispatch("hospital/deleteService", this.selectedItem.id)
        .then(() => {
          this.toggleDelete();
          this.getData();
        });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input,
.v-text-field--outlined::v-deep input::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}
</style>
